.work__title{
  padding-bottom: 3rem;
  padding-top: 5rem !important;
}
.work__btns-wrapper{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.bn5 {
  padding: 0.6em 2em;
  border: 1px solid white; 
  outline: none;
  color: #55D983;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  text-decoration: none;
}


.bn5:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowingbn5 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.bn5:active {
  color: #000;
}

.bn5:active:after {
  background: transparent;
}

.bn5:hover:before {
  opacity: 1;
}

.bn5:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2C2C2C;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.disabled-link {
  pointer-events: none; /* Відключити всі події */
  cursor: default; /* Змінити курсор */
  opacity: 0.6; /* Змінити непрозорість */
  color: #aaa; /* Змінити колір тексту */
  text-decoration: none; /* Забрати підкреслення, якщо є */
}

.hero__wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.hero__description {
  display: flex;
  flex-direction: column;
  max-width: 486px;
  max-height: 411px;
  padding: 50px;
  background-color: #2C2C2C;
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 522px;
  border-radius: var(--border-radius);
}

.hero__name {
  color: var(--main);
  font-weight: 900;
  font-size: clamp(1.375rem, 1.193rem + 0.91vw, 1.875rem);
  line-height: 2.25rem;
  margin-right: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.hero__social-list-wrappe {
  max-width: 120px;
}

.hero__social-list {
  display: flex;
  list-style: none;
  padding: 0;
}

.hero__social_wrapper {
  display: flex;
  flex-direction: row;
}

.hero__social-item {
  margin-right: 10px;
}

.hero__social-item img {
  width: 30px;
  height: 30px;
}

.hero_dynamic-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.25rem;
  letter-spacing: .025em;
}

.hero_dynamic-info span {
  font-weight: 900;
}

.hero_dynamic-info code {
  color: #32A10F;
}

.hero__social-personal {
  color: rgb(200 200 200);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.text-white {
  color: var(--main);
  font-weight: 900;
  font-weight: 600;
}

.hero__workplace img {
  width: 100%;
  height: auto;
}



.bn632-hover {
  display: flex;
  align-items: center;
  width: 120px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 40px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  transition: all .4s ease-in-out;
}

.bn632-hover span {
  text-decoration: none;
}


.bn632-hover:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.link-to-download{
  text-decoration: none;
}

.bn632-hover.bn22 {
  background-image: linear-gradient(to right, #68DF99, #65B56D, #02F243, #52830A);
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

@media (max-width: 1100px) {
  .hero__workplace img {
    width: 25rem;
  }

  .hero__description {
    padding: 10px;
    left: 300px;
  }
}

@media (max-width: 790px) {
  .hero__workplace img {
    width: 16rem;
  }

  .hero__name {
    line-height: inherit;
  }

  .hero__description {
    top: -16px;
    left: 177px;
  }
}

@media (max-width: 670px) {
  .hero__workplace {
    display: none;
    margin-top: 2rem;
  }

  .hero__description {
    left: -3px;
  }

  .about__title {
    padding-top: 14rem;
  }
}
@media (max-width: 400px) {
  .hero__social_wrapper{
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .hero_dynamic-info{
    font-size: 15px;
  }
}
.header {
  margin-top: 32px;
}

.header__container {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.header__wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 2rem;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.header__hero {
  text-decoration: none;
}

.header__hero>span {
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: var(--main);
}

.header__surname {
  margin-right: 0.25rem;
}

.first__name {
  font-weight: 700;
}

.lng__btns-wrapper {
  margin-left: 2rem;
}

.lng__btns-wrapper>a {
  margin-left: 1rem;
}

.lng__btns-wrapper>a.disabled-link {
  pointer-events: none;
  opacity: 0.6;
}


@media (max-width: 670px) {
  .lng__btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: center;
    align-items: center;

    .lng__btns-wrapper>a {
      padding: 1em 1em;
    }

  }
}

@media (max-width: 500px) {


  .header__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.experience__container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.experience__work-header {
  text-shadow: 10px 10px 10px rgba(0, 0, 0, .5), 20px 20px 20px rgba(0, 0, 0, .4), 30px 30px 30px rgba(0, 0, 0, .1);
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (max-width: 500px) {
  .experience__container {
    flex-direction: column;
  }
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.about__title{
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: -.025em;
  font-weight: 800;
  margin-bottom: 1rem;
  text-shadow: #55D983 1px 0 5px;
  padding-top: 2rem;
}

.text-white{
  color: var(--main) !important;
}

.about__title span {
  color: #55D983;
}

.about__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  position: relative;
  z-index: 0;
  justify-content: center;
}

.about__description {
  background-color: #2C2C2C;
  padding: 2rem;
  border-radius: 20px;
  flex: 1 1 40rem;
  max-width: 63rem;
}

.about__desctiption-item {
  font-size: 1.2rem;
  line-height: 1.75;
  color: rgb(200 200 200);
  max-width: 100%;
  list-style-type: disc;
}

.about__photo {
  max-width: 300px;
  flex: 1 1 20rem;
  position: relative;
}

.about__photo img {
  border-radius: 78px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Медіа-запити */
@media (max-width: 1024px) {
  .about__description {
    max-width: 100%;
  }

  .about__description {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .about__title {
    font-size: 1.5rem;
  }

  .about__description {
    padding: 1rem;
  }

  .about__title {
    padding-top: 4rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .about__title {
    padding-top: 20rem !important;
  }
}